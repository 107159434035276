import Layout from "./components/Layout";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import StarRating from "./components/starRating/StarRating";
import {useEffect, useState, useCallback} from "react";
import ReactGA from "react-ga4";

function googleGTMInit (w,d,s,l,i) { w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    }

const merge = require('lodash.merge');
const HomePage = ({companySlug, fullApiUrl}) => {
    const [, updateState] = useState();
    const [defaultRating, setDefaultRating] = useState(null);
    const forceUpdate = useCallback(() => updateState({}), []);
    const [data, setData] = useState(null);
    const [isMissingPage, setIsMissingPage] = useState(false);
    const [cmdOverride, setCmdOverride] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname

    const [searchParams] = useSearchParams();

    let lang = searchParams.get('lang');
    let selectedRating = searchParams.get('ir') ?? null;

    const [contentLang, setContentLang] = useState(null);

    useEffect(() => {
        const fetchBusinessInfo = async () => {
            try {
                let response;
                if (fullApiUrl) {
                    response = await fetch(fullApiUrl);
                } else {
                    response = await fetch(`${process.env.REACT_APP_API}${companySlug ? "/" + companySlug : path}/info?lang=` + lang);
                }

                if (response.status === 404) {
                    setIsMissingPage(true)
                } else {
                    const data = await response.json();
                    setData(data)
                    setIsMissingPage(false)
                    document.title = data?.business?.name
                    setContentLang(data?.business?.language)
                    let gacode = data?.extra?.ga_code
                    if ( gacode ) {
                        if ( gacode.startsWith('GTM-') ) {
                            googleGTMInit(window,document,'script','dataLayer', gacode);
                        } else if ( gacode.startsWith('GA-') || gacode.startsWith('G-') ) {
                            ReactGA.initialize(gacode);
                        }
                    }
                }
            } catch (e) {
                console.log(123, e)
                setIsMissingPage(true)
            }
        };
        fetchBusinessInfo();
    }, [fullApiUrl, companySlug, lang, path])

    useEffect(() => {
        const action = (e) => {
            // console.log("Changing: ", e, e.data)
            setData(d => merge(d, e.detail))
            forceUpdate()
            // setData(merge(data, e.detail))
        }
        window.addEventListener('changeData', action);
        return () => window.removeEventListener("changeData", action);
    }, [forceUpdate])

    useEffect(() => {
        const changePath = (e) => {
            navigate(e.detail.path)
        }
        window.addEventListener('changePath', changePath);
        return () => window.removeEventListener("changePath", changePath);
    }, [location, navigate])

    useEffect(() => {
        const rate = (e) => {
            setDefaultRating(e.detail.rating)
        }
        window.addEventListener('rate', rate);
        return () => window.removeEventListener("rate", rate);
    }, [])

    useEffect(() => {
        const override = (e) => {
            setCmdOverride(e.detail.override)
        }
        window.addEventListener('override', override);
        return () => window.removeEventListener("override", override);
    }, [])

    if (isMissingPage) return <div className="tw1-flex tw1-h-screen tw1-justify-center tw1-items-center tw1-text-5xl">404</div>
    if (!data) return <div/>;
    return (
        <Layout>
            <Header
                name={data?.business.name}
                header={data?.business?.header}
            />

            <StarRating
                cmdOverride={cmdOverride}
                setCmdOverride={setCmdOverride}
                defaultRating={defaultRating}
                path={path}
                name={data?.business?.name}
                logo={data?.business?.logourl}
                message={data?.business?.message}
                stars={data?.stars}
                platforms={data?.platforms}
                requestForm={data?.request}
                surveyForm={data?.survey}
                subcategories={data?.category?.subcategories}
                subcategoriesTranslated={data?.category?.subcategories_translated ?? false}
                questionnaire={data?.questionnaire}
                isConfirmationActive={data?.category?.isactive}
                translatedData={data?.extra?.translated_data}
                selectedRating={selectedRating}
            />
            <Footer
                footer={data?.business?.footer}
                socials={data?.social}
                lang={contentLang}
            />
        </Layout>
    );
};

export default HomePage;

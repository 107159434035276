import {Dialog} from "@headlessui/react";

import Platforms from "../../components/requestForm/Platforms";
import Close from "../../components/icons/Close";
import React, { useState } from 'react';

const isStandalone = process.env.REACT_APP_IS_STANDALONE === "true";

const RequestForm = ({questionnaire, categoriesRating, value, setValue, platforms, requestForm, setOverride, override, translatedData}) => {

    const [toast, setToast] = useState('');

    const answers = categoriesRating
        .map(item => item.answer)
        .filter(answer => answer) // Filter out any undefined or empty answers
        .join('\n'); // Join all answers with a new line

    const copyAllAnswers = () => {
        navigator.clipboard.writeText(answers);
        setToast(translatedData?.copy_all_success_message);
        setTimeout(() => setToast(''), 2000); // Hide toast after 2 seconds
    };

    if (!isStandalone) {
        return (
                <div
                    onClose={() => setValue(0)}
                    className='tw1-absolute tw1-z-50 tw1-w-full tw1-top-[10px] tw1-left-1/2 tw1-transform tw1--translate-x-1/2 '
                >
                    <div
                        className='tw1-inset-0 tw1-flex tw1-items-center tw1-justify-center tw1-p-4 tw1-z-50'>
                        <div
                            className='tw1-w-full tw1-rounded tw1-bg-white  tw1-max-w-[627px] tw1-rouded-[4px] tw1-relative tw1-z-50'>
                            <div className='tw1-max-w-[548px] tw1-mx-auto tw1-py-12 tw1-px-6'>
                                {requestForm?.headermessage ? (
                                    <div
                                        style={{whiteSpace: "pre-wrap"}}
                                        className='tw1-text-xl tw1-font-medium tw1-mb-6 tw1-text-center tw1-text-[#374C51]'>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: requestForm?.headermessage,
                                            }}
                                        ></div>
                                    </div>
                                ) : null}
                                <Platforms platforms={platforms}/>
                                {answers && questionnaire.can_copy_answers && <div>
                                    <div style={{
                                        background: 'white',
                                        border: '1px solid #e8e8e8',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        margin: '30px 0 0 0'
                                    }}>
                                        {categoriesRating.map((item, index) => (
                                            <div key={index} style={{
                                                marginBottom: "30px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>
                                                {item.answer && (
                                                    <p
                                                        style={{margin: '0', padding: '0 15px', width: '100%'}}
                                                    >
                                                        {item.answer}
                                                    </p>
                                                )}
                                            </div>
                                        ))}
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'end'
                                        }}>
                                            <button
                                                onClick={copyAllAnswers}
                                                style={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: 'fit-content',
                                                    fontSize: '14px',
                                                    background: '#2d88ff',
                                                    color: '#fff',
                                                    borderRadius: '5px',
                                                    padding: '5px 8px',
                                                    border: '0'
                                                }}
                                            >
                                                {translatedData?.copy_all}
                                            </button>
                                        </div>

                                        {toast && (
                                            <div style={{
                                                position: 'fixed',
                                                bottom: '20px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                backgroundColor: '#333',
                                                color: '#fff',
                                                padding: '10px 20px',
                                                borderRadius: '5px',
                                                zIndex: 1000
                                            }}>
                                                {toast}
                                            </div>
                                        )}
                                    </div>
                                </div> }
                            </div>
                            <div
                                className='tw1-absolute tw1-top-4 tw1-right-4 tw1-cursor-pointer'
                                onClick={() => setValue(0)}
                            >
                                <Close/>
                            </div>
                            <div
                                onClick={e => setOverride("survey")}
                                className='tw1-border-t tw1-border-[#dae1e2] tw1-bg-[#b5bcc71a] tw1-p-7 tw1-text-xs tw1-font-medium tw1-text-center tw1-text-[#374C51]
           form-footer tw1-cursor-pointer tw1-underline'>
                                {requestForm?.footermessage ? (
                                <div
                                    dangerouslySetInnerHTML={{ __html: requestForm?.footermessage, }}
                                ></div>)
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
    return (
        <Dialog
            open={(value && value > 0) || !!override}
            onClose={() => setValue(0)}
            className='tw1-relative tw1-z-50'
        >
            <div
                className='tw1-fixed tw1-inset-0 tw1-flex tw1-items-center tw1-justify-center tw1-p-4 tw1-bg-gray-800 tw1-bg-opacity-50'>
                <Dialog.Panel
                    className='tw1-w-full tw1-rounded tw1-bg-white  tw1-max-w-[627px] tw1-rouded-[4px] tw1-relative'>
                    <div className='tw1-max-w-[548px] tw1-mx-auto tw1-py-12 tw1-px-6'>
                        {requestForm?.headermessage ? (
                            <Dialog.Title
                                className='tw1-text-xl tw1-font-medium tw1-mb-6 tw1-text-center tw1-text-[#374C51]'>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: requestForm?.headermessage,
                                    }}
                                ></div>
                            </Dialog.Title>
                        ) : null}
                        <Platforms platforms={platforms}/>
                        {answers && questionnaire.can_copy_answers && <div>
                            <div style={{
                                background: 'white',
                                border: '1px solid #e8e8e8',
                                borderRadius: '10px',
                                padding: '10px',
                                margin: '30px 0 0 0'
                            }}>
                                {categoriesRating.map((item, index) => (
                                    <div key={index} style={{
                                        marginBottom: "30px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        {item.answer && (
                                            <p
                                                style={{margin: '0', padding: '0 15px', width: '100%'}}
                                            >
                                                {item.answer}
                                            </p>
                                        )}
                                    </div>
                                ))}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'end'
                                }}>
                                    <button
                                        onClick={copyAllAnswers}
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: 'fit-content',
                                            fontSize: '14px',
                                            background: '#2d88ff',
                                            color: '#fff',
                                            borderRadius: '5px',
                                            padding: '5px 8px',
                                            border: '0'
                                        }}
                                    >
                                        {translatedData?.copy_all}
                                    </button>
                                </div>

                                {toast && (
                                    <div style={{
                                        position: 'fixed',
                                        bottom: '20px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: '#333',
                                        color: '#fff',
                                        padding: '10px 20px',
                                        borderRadius: '5px',
                                        zIndex: 1000
                                    }}>
                                        {toast}
                                    </div>
                                )}
                            </div>
                        </div> }
                    </div>
                    <div
                        className='tw1-absolute tw1-top-4 tw1-right-4 tw1-cursor-pointer'
                        onClick={() => setValue(0)}
                    >
                    <Close/>
                    </div>
                    <div
                        onClick={e => setOverride("survey")}
                        className='tw1-border-t tw1-border-[#dae1e2] tw1-bg-[#b5bcc71a] tw1-p-7 tw1-text-xs tw1-font-medium tw1-text-center tw1-text-[#374C51]
           form-footer tw1-cursor-pointer tw1-underline'>
                        {requestForm?.footermessage ? (
                                <div
                                    style={{whiteSpace: "pre-wrap"}}
                                    dangerouslySetInnerHTML={{__html: requestForm?.footermessage,}}
                                ></div>)
                            : null}
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};

export default RequestForm;

import {Dialog} from "@headlessui/react";
import Close from "../../components/icons/Close";
import SmallStars from "../starRating/SmallStars";
import { AiOutlineSend } from "react-icons/ai";
import {useState, useEffect} from "react";
import Feedback from "./Feedback";

const isStandalone = process.env.REACT_APP_IS_STANDALONE === "true";

const ConfirmationDialog = ({
    setOverride,
    confirmation,
    setConfirmation,
    onClickSubmit,
    categories,
    categoriesTranslated,
    questionnaire,
    mainStarValue,
    ratingStars,
    stars,
    categoriesRating,
    setCategoriesRating,
    isConfirmationOpen,
    setIsConfirmationOpen,
    translatedData
}) => {

    const [smallStarsValue, setSmallStarsValue] = useState({0: 0, 1: 0, 2: 0});
    const [feedbackState, setFeedbackState] = useState({});
    const [feedbackError, setFeedbackError] = useState(false);

    useEffect(() => {
        setCategoriesRating([]);
    }, []);

    const canValidate = (value, trigger) => {
        if ( trigger === 'never_show') {
            return false;
        }

        if ( trigger === 'always_show') {
            return true;
        }

        if ( value === 0 ) {
            return false;
        }

        if ( trigger === 'four_or_less') {
            return value <= 4;
        }

        if ( trigger === 'three_or_less') {
            return value <= 3;
        }

        if ( trigger === 'two_or_less') {
            return value <= 2;
        }

        if ( trigger === 'one_only') {
            return value === 1;
        }

        return false;
    }

    let validationMap = {
        'main': canValidate(mainStarValue, questionnaire.questionnaire_trigger) && questionnaire.main_question_required,
    }

    categories?.map((category, index) => {
        validationMap[index] = canValidate(smallStarsValue[index], questionnaire.questionnaire_trigger) &&questionnaire['question_required_' + index];
    })

    const submit = () => {
        let isValid = true;

        for (const [index, value] of Object.entries(validationMap)) {
            if (value && (feedbackState[index] === undefined || feedbackState[index].trim() === '')) {
                isValid = false;
                break;
            }
        }
        setFeedbackError(!isValid);
        if (isValid) {
            onClickSubmit();
        }
    }

    if (!isStandalone) {
        return (
            <div
                className='tw1-absolute tw1-z-50 tw1-w-full tw1-top-[10px] tw1-left-1/2 tw1-transform tw1--translate-x-1/2 '
            >
                <div
                    className='tw1-z-50 tw1-flex tw1-items-center tw1-justify-center tw1-p-4 ' >
                    <div className='tw1-w-full tw1-rounded tw1-bg-white  tw1-max-w-[627px] tw1-rouded-[4px] tw1-relative tw1-max-h-full tw1-overflow-auto tw1-z-50'>
                         <div className='tw1-max-w-[548px] tw1-mx-auto tw1-py-12 tw1-px-6'>
                             <div>
                                 {ratingStars}
                                 {questionnaire.main_question_field_active && canValidate(mainStarValue, questionnaire.questionnaire_trigger) && <Feedback
                                     index='main'
                                     category='main'
                                     categoriesRating={categoriesRating}
                                     setCategoriesRating={setCategoriesRating}
                                     feedbackState={feedbackState}
                                     setFeedbackState={setFeedbackState}
                                     questionnaireTrigger={questionnaire.questionnaire_trigger}
                                     canShowQuestion={questionnaire.main_question_text_show}
                                     questionText={questionnaire.main_question_text}
                                     selectedStarValue={mainStarValue}
                                     isRequired={questionnaire.main_question_required}
                                     translatedData={translatedData}
                                     style={{marginTop: '30px'}}
                                 />
                                 }
                             </div>
                             <div className='tw1-flex tw1-items-center tw1-flex-wrap tw1-justify-center tw1-flex-col'>
                                 {categories?.map((category, index) => {
                                     return (
                                         <div key={category} style={{ padding: '10px 40px', width: '100%' }}>
                                             <SmallStars
                                                 categoriesTranslated={categoriesTranslated[index] ?? category}
                                                 category={category}
                                                 stars={stars}
                                                 categoriesRating={categoriesRating}
                                                 setCategoriesRating={setCategoriesRating}
                                                 translatedData={translatedData}
                                                 setSmallStarsValue={setSmallStarsValue}
                                                 smallStarsValue={smallStarsValue}
                                                 index={index}
                                             />
                                             {questionnaire['question_field_active_' + index] && canValidate(smallStarsValue[index], questionnaire.questionnaire_trigger) && <Feedback
                                                 index={index}
                                                 category={category}
                                                 categoriesRating={categoriesRating}
                                                 setCategoriesRating={setCategoriesRating}
                                                 feedbackState={feedbackState}
                                                 setFeedbackState={setFeedbackState}
                                                 questionnaireTrigger={questionnaire.questionnaire_trigger}
                                                 canShowQuestion={questionnaire['question_text_show_' + index]}
                                                 questionText={questionnaire['question_text_' + index]}
                                                 isRequired={questionnaire['question_required_' + index]}
                                                 selectedStarValue={smallStarsValue[index]}
                                                 translatedData={translatedData}
                                             />
                                             }
                                         </div>
                                     );
                                 })}

                                 <button type="submit" className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-gray-900 hover:border-transparent rounded" onClick={submit}>
                                     <AiOutlineSend style={{fontSize: '31px', color: '#585757'}} />
                                 </button>

                                 { feedbackError && <div style={{color: '#b00707', paddingTop: '20px'}}>{translatedData?.fill_inputs}</div>}
                             </div>
                        </div>
                        <div
                            className='tw1-absolute tw1-top-4 tw1-right-4 tw1-cursor-pointer'
                            onClick={() => {setConfirmation(false); setOverride(null); }}
                        >
                            <Close/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return  confirmation ? (
        <Dialog
            open={isConfirmationOpen}
            onClose={() => {setConfirmation(false);}}
            className='tw1-relative tw1-z-50'>
            <div
                className='tw1-fixed tw1-inset-0 tw1-flex tw1-items-center tw1-justify-center tw1-p-4 tw1-bg-gray-800 tw1-bg-opacity-50'>
                <Dialog.Panel
                    className='tw1-w-full tw1-rounded tw1-bg-white  tw1-max-w-[627px] tw1-rouded-[4px] tw1-relative tw1-max-h-full tw1-overflow-auto tw1-z-50'>
                    <div className='tw1-max-w-[548px] tw1-mx-auto tw1-py-12 tw1-px-6'>
                        <Dialog.Title
                            className='tw1-text-xl tw1-font-medium tw1-mb-6 tw1-text-center tw1-text-[#374C51]'>
                            <div>
                                {ratingStars}
                                {questionnaire.main_question_field_active && canValidate(mainStarValue, questionnaire.questionnaire_trigger) && <Feedback
                                    index='main'
                                    category='main'
                                    categoriesRating={categoriesRating}
                                    setCategoriesRating={setCategoriesRating}
                                    feedbackState={feedbackState}
                                    setFeedbackState={setFeedbackState}
                                    questionnaireTrigger={questionnaire.questionnaire_trigger}
                                    canShowQuestion={questionnaire.main_question_text_show}
                                    questionText={questionnaire.main_question_text}
                                    selectedStarValue={mainStarValue}
                                    isRequired={questionnaire.main_question_required}
                                    translatedData={translatedData}
                                    style={{marginTop: '30px'}}
                                />
                                }
                            </div>
                        </Dialog.Title>
                        <div className='tw1-flex tw1-items-center tw1-flex-wrap tw1-justify-center tw1-flex-col'>
                            {categories?.map((category, index) => {
                                return (
                                    <div key={category} style={{ padding: '10px 40px', width: '100%' }}>
                                        <SmallStars
                                            categoriesTranslated={categoriesTranslated[index] ?? category}
                                            category={category}
                                            stars={stars}
                                            categoriesRating={categoriesRating}
                                            setCategoriesRating={setCategoriesRating}
                                            translatedData={translatedData}
                                            setSmallStarsValue={setSmallStarsValue}
                                            smallStarsValue={smallStarsValue}
                                            index={index}
                                        />
                                        {questionnaire['question_field_active_' + index] && canValidate(smallStarsValue[index], questionnaire.questionnaire_trigger) && <Feedback
                                            index={index}
                                            category={category}
                                            categoriesRating={categoriesRating}
                                            setCategoriesRating={setCategoriesRating}
                                            feedbackState={feedbackState}
                                            setFeedbackState={setFeedbackState}
                                            questionnaireTrigger={questionnaire.questionnaire_trigger}
                                            canShowQuestion={questionnaire['question_text_show_' + index]}
                                            questionText={questionnaire['question_text_' + index]}
                                            isRequired={questionnaire['question_required_' + index]}
                                            selectedStarValue={smallStarsValue[index]}
                                            translatedData={translatedData}
                                        />
                                        }
                                    </div>
                                );
                            })}

                            <button type="submit" className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-gray-900 hover:border-transparent rounded" onClick={submit}>
                                <AiOutlineSend style={{fontSize: '31px', color: '#585757'}} />
                            </button>

                            { feedbackError && <div style={{color: '#b00707', paddingTop: '20px'}}>{translatedData?.fill_inputs}</div>}
                        </div>
                    </div>
                    <div
                        className='tw1-absolute tw1-top-4 tw1-right-4 tw1-cursor-pointer'
                        onClick={() => setIsConfirmationOpen(false)}
                    >
                        <Close  />
                    </div>

                </Dialog.Panel>
            </div>
        </Dialog>)
                : null
    ;
};

export default ConfirmationDialog;
